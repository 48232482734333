<template>
  <div class="login">
    <img src="@/assets/img/argo-logo-without-text.svg" alt width="92" height="27" />
    <h1 class="mb-10">{{ $t('login.login') }}</h1>

    <!-- @todo исправить для i18n после правки бэка -->
    <div v-if="error" class="error--text mb-4">{{ error }}</div>

    <login-form @submit="sendAuthData" />
  </div>
</template>

<script>
// node_modules
import { mapActions, mapGetters } from 'vuex';

// Constants
import { PROFILE } from '@/constants/routes';
import { SET_USER_ROLE } from '@/store/modules/user/actions/types';
import { LOG_IN, LOG_IN_PAGE } from '@/constants/analyticsActions';
import { RIGHTS } from '@/store/modules/user/types';

// Services
import analyticsService from '@/services/analytics';
import authService from '@/services/auth';
import { defineRulesFor } from '@/config/ability';
// Components
import LoginForm from '@/components/LoginForm.vue';

const ANALYTICS_DELAY = 10000;

export default {
  name: 'Login',

  components: { LoginForm },

  data: () => ({
    error: '',
  }),

  computed: {
    ...mapGetters('user', {
      userRights: RIGHTS,
    }),
  },

  mounted() {
    this.$options.analyticsTimer = setTimeout(() => {
      analyticsService.track(LOG_IN_PAGE);
    }, ANALYTICS_DELAY);
  },

  beforeDestroy() {
    clearTimeout(this.$options.analyticsTimer);
  },

  methods: {
    ...mapActions('user', {
      setUserRole: SET_USER_ROLE,
    }),

    async sendAuthData({ email, password, conditions }) {
      try {
        const { role } = await authService.login(email, password, conditions);

        this.setUserRole(role);

        this.$ability.update(defineRulesFor(role, this.userRights));

        analyticsService.track(LOG_IN, analyticsService.createLoginPayload(email, role));

        this.$router.push({ name: PROFILE });
      } catch (error) {
        this.error = Object.values(error.response.data)
          .map(item => this.$t(item))
          .join(' ');
      }
    },
  },

  analyticsTimer: null,
};
</script>
